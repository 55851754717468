import { Edit } from '@assets';
import Skeleton from '@components/Common/Skeleton';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

// material
import {
  Alert,
  Button,
  Card,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { isPropertyPaymentTermId } from '@utils/common';
import { CONSTANT_PAGES_URL } from '@utils/constants';
import { bookingInfo, progress } from '@utils/customText';

// components
const MobileHeader = lazy(() => import('@components/Common/Mobile/Header'));
const GuestDetailForm = lazy(() => import('../GuestDetailForm'));
const CheckinCheckoutDetail = lazy(() =>
  import('@components/DetailPage/CheckinCheckoutField')
);
const PaymentDetails = lazy(() => import('../PaymentDetail'));
const ContentDetail = lazy(() => import('@components/Common/Content'));
const PropertyInfo = lazy(() => import('../PropertyInfo'));
const ApplyCoupon = lazy(() => import('../CouponApply'));
const PartialSwitch = lazy(() => import('../PartialSwitch'));

// hooks
import useBookingDetails from '@customhooks/useBookingDetails';
import { useAccessTokens } from '@customhooks/useTokens';

import { bookingStatus } from '@actions/propertiesAction';

// Reducers
import { setOpen } from '@reducers/globalReducers';

// css
import { calculateMinimumStay } from '@utils/common';
import dayjs from 'dayjs';
import './bookingInfoMobile.scss';
import { Redeem } from '@mui/icons-material';
import RedeemeElicash from '@components/Common/wallet/RedeemeElicash';

// component start
export const BookingInfoMobile = (props) => {
  const {
    setInputFields,
    inputFields,
    handleSubmit,
    setTermsChecked,
    termsChecked,
    errors,
    handleInputChange,
    touchedFields,
    setBasicDetails,
    basicDetails,
    fetchingUpdatingPricing,
    gettingUpdatedPricing,
    loader,
    setIsToggleOpen,
    isToggleOpen,
    applyButtonClicked,
    setApplyButtonClicked,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const { pagesDetailsSectionsData } = useSelector((state) => state.property);
  const isPolicyContent = pagesDetailsSectionsData;
  const {
    totalNumberOfNights,
    fetchingBookingDetailsStatus,
    fetchingBookingDetailsRes,
    setIsModifyClicked,
    isModifyClicked,
  } = useBookingDetails();
  const max_occupancy = localStorage.getItem('max_occupancy');
  const { accessToken, refreshToken } = useAccessTokens();

  const { open } = useSelector((state) => state.glob);
  const { minimumStay, propertyDetail } = useSelector(
    (state) => state.property
  );
  const [minimumDays, setMinimumDays] = useState(0);
  const searchParams = new URLSearchParams(location?.search);
  const propertyId = searchParams.get('propertyId');
  const bookingId = searchParams.get('id');
  const appliedCouponCode = {
    customCoupon: null,
    sitewideCoupon: null,
    doubleDipCoupon: null,
    hollowCoupon: null,
  };

  if (
    fetchingBookingDetailsRes?.attached_coupons?.length > 0 ||
    fetchingBookingDetailsRes?.Order?.Coupons.length > 0
  ) {
    if (fetchingBookingDetailsRes?.attached_coupons?.length > 0) {
      fetchingBookingDetailsRes?.attached_coupons?.forEach((coupon) => {
        let couponObj = {
          name: coupon.Coupon.code,
          couponId: coupon.coupon_id,
          orderID: coupon.order_id,
          discountAmount: coupon.discount_amount,
        };
        if (coupon.Offer.is_custom && !coupon.Coupon.is_double_dip) {
          appliedCouponCode.customCoupon = couponObj;
        } else if (!coupon.Offer.is_custom) {
          appliedCouponCode.sitewideCoupon = couponObj;
        } else if (coupon.Coupon.is_double_dip) {
          appliedCouponCode.doubleDipCoupon = couponObj;
        }
      });
    }
    if (fetchingBookingDetailsRes?.Order?.Coupons.length > 0) {
      fetchingBookingDetailsRes?.Order?.Coupons?.forEach((coupon) => {
        let couponObj = {
          name: coupon.OrderCoupon.Coupon.code,
          couponId: coupon.OrderCoupon.Coupon.id,
          orderID: fetchingBookingDetailsRes?.Order?.id,
          discountAmount: coupon.OrderCoupon.discount_amount,
        };

        if (coupon.Offer.hollow_offer) {
          appliedCouponCode.hollowCoupon = couponObj;
        }
      });
    }
  }

  const handleNavigateToBackPage = () => {
    dispatch(bookingStatus(false));
    const updatedName = fetchingBookingDetailsRes?.Property?.name.replace(
      /[\s-]+/g,
      '-'
    );

    const formattedCityName = fetchingBookingDetailsRes?.Property?.Address?.city
      .replace(/ /g, '-')
      .toLowerCase();
    const { slug } = propertyDetail;

    const reviewBookingURL = slug
      ? `/villas-in-${formattedCityName}/${slug}?propertyId=${propertyId}&bookingId=${bookingId}`
      : `/villas-in-${formattedCityName}?propertyId=${propertyId}&bookingId=${bookingId}`;
    navigate(reviewBookingURL);
  };

  const handleLoginModalOpen = () => {
    dispatch(setOpen(true));
  };

  const pathId = fetchingBookingDetailsRes?.Order?.id;

  useEffect(() => {
    setIsToggleOpen(isPropertyPaymentTermId(gettingUpdatedPricing));
  }, [gettingUpdatedPricing]);
  const checkInDate = dayjs(fetchingBookingDetailsRes?.checkin_on).format(
    'DD MMM, YYYY'
  );
  const checkOutDate = dayjs(fetchingBookingDetailsRes?.checkout_on).format(
    'DD MMM, YYYY'
  );

  const millisecondsDiff = dayjs(checkOutDate).diff(dayjs(checkInDate), 'day');
  const numberOfNights = Math.ceil(millisecondsDiff);
  useEffect(() => {
    const minStays = calculateMinimumStay(
      checkInDate,
      checkOutDate,
      minimumStay
    );
    setMinimumDays(minStays);
  }, [checkInDate, checkOutDate]);

  const checkboxEnable = true;
  const checkboxDisable = false;

  return (
    <>
      <Suspense
        fallback={
          <>
            <Skeleton component='h1' variant='text' height={45} />
            <Skeleton component='h3' variant='text' height={20} />
          </>
        }
        mode='concurrent'
      >
        <MobileHeader
          title='Review Booking Details'
          onClick={handleNavigateToBackPage}
        />
      </Suspense>
      <Container className='bookingContainerMobile' maxWidth='xl'>
        <Grid container spacing={0}>
          {/* selected property detail   */}
          <Grid className='EL-PDInfo' item xs={12}>
            <Suspense
              fallback={
                <div className='flex full-width sp-bw'>
                  <Skeleton variant='rectangular' height={105} width={'33%'} />{' '}
                  <Skeleton variant='rectangular' height={105} width={'63%'} />
                </div>
              }
            >
              <PropertyInfo
                fetchingBookingDetailsStatus={fetchingBookingDetailsStatus}
                fetchingBookingDetailsRes={fetchingBookingDetailsRes}
              />
            </Suspense>
          </Grid>
          {/* checkin on - checkout on detail   */}
          <Grid className='EL-PDcheckinCheckout' item xs={12}>
            <Typography variant='h6' gutterBottom>
              <span>
                {' '}
                {bookingInfo.bookingFor.booking} {totalNumberOfNights}{' '}
                {bookingInfo.bookingFor.nights}
              </span>
              <Button
                onClick={() => setIsModifyClicked(true)}
                startIcon={<img src={Edit} alt='icon' />}
                color='secondary'
                disabled={false}
                size='small'
                variant='text'
              >
                {bookingInfo.modify}
              </Button>
            </Typography>
            <div
              className='El-checkinCheckoutBooking'
              // className={`${
              //   isModifyClicked && 'El-checkinCheckoutDisable'
              // } El-checkinCheckoutBooking`}
            >
              <Suspense
                fallback={<Skeleton variant='rectangular' height={129} />}
              >
                <CheckinCheckoutDetail
                  id={fetchingBookingDetailsRes?.property_id}
                  setBasicDetails={setBasicDetails}
                  basicDetails={basicDetails}
                  isbookNow={false}
                  max_occupancy={max_occupancy}
                  isModifyClicked={isModifyClicked}
                  setIsModifyClicked={setIsModifyClicked}
                  standard_guests={
                    fetchingBookingDetailsRes?.standard_occupancy
                  }
                  max_children={fetchingBookingDetailsRes?.max_children}
                  max_adults={fetchingBookingDetailsRes?.max_adults}
                  gettingUpdatedPricing={gettingUpdatedPricing}
                  applyButtonClicked={applyButtonClicked}
                  staahMapping={fetchingBookingDetailsRes?.staahMapping}
                />
                {fetchingBookingDetailsStatus &&
                  numberOfNights < minimumDays && (
                    <Alert
                      severity='error'
                      variant='standard'
                      className='EL-minStayAlert'
                    >
                      Please select at least {minimumDays} nights
                    </Alert>
                  )}
              </Suspense>
            </div>
          </Grid>
          {/* Guest detail form   */}
          <Grid className='EL-PDGuestForm' item xs={12}>
            <div className='EL-PDGuestFormInner'>
              <Typography variant='h6' gutterBottom>
                {bookingInfo.guestDetails}
              </Typography>
              <Typography variant='caption' display='block' gutterBottom>
                <b>{bookingInfo.note.note}</b> {bookingInfo.note.noteText}
              </Typography>
              {!accessToken && !refreshToken ? (
                <Button
                  disableElevation
                  variant='outlined'
                  size='large'
                  color='secondary'
                  onClick={handleLoginModalOpen}
                  fullWidth
                  endIcon={<ChevronRightRoundedIcon />}
                  className='El-reviewLoginCta'
                >
                  <span>Login now to checkout</span>
                </Button>
              ) : (
                ''
              )}
              <Suspense
                fallback={<Skeleton variant='rectangular' height={250} />}
              >
                <GuestDetailForm
                  setInputFields={setInputFields}
                  inputFields={inputFields}
                  errors={errors}
                  handleInputChange={handleInputChange}
                />
              </Suspense>
            </div>
          </Grid>
          {/* Policy and Rules */}
          <Suspense fallback={<Skeleton variant='rectangular' height={270} />}>
            <ContentDetail
              url={CONSTANT_PAGES_URL.bookingReview}
              isExpand={false}
            />
          </Suspense>
          <Suspense fallback={<div>{progress.loading}</div>}>
            <ApplyCoupon
              id={pathId}
              appliedCouponCode={appliedCouponCode}
              fetchingBookingDetailsRes={fetchingBookingDetailsRes}
              propertyId={propertyId}
              applyButtonClicked={applyButtonClicked}
              setApplyButtonClicked={setApplyButtonClicked}
            />
          </Suspense>
          {accessToken && refreshToken && (
            <Card
              variant='outlined'
              className='EL-RedeemClash El-paymentDetail El-PartialSwitch'
            >
              <RedeemeElicash
                checkboxEnable={checkboxEnable}
                checkboxDisable={checkboxDisable}
                fetchingBookingDetailsRes={fetchingBookingDetailsRes}
              />
            </Card>
          )}

          {/* Partial Switch */}
          <Suspense fallback={<div>{progress.loading}</div>}>
            {/* PARTIAL PAYMENT SWITCH */}
            <PartialSwitch
              setIsToggleOpen={setIsToggleOpen}
              isToggleOpen={isToggleOpen}
              outstandingPayment={
                fetchingBookingDetailsRes?.Property?.PaymentTerms &&
                fetchingBookingDetailsRes?.Property?.PaymentTerms[0]?.name
              }
            />
          </Suspense>
          <Grid className='EL-PaymentDetails' item xs={12}>
            <Suspense
              fallback={<Skeleton variant='rectangular' height={350} />}
            >
              <PaymentDetails
                handleSubmit={handleSubmit}
                setTermsChecked={setTermsChecked}
                termsChecked={termsChecked}
                fetchingUpdatingPricing={fetchingUpdatingPricing}
                gettingUpdatedPricing={gettingUpdatedPricing}
                fetchingBookingDetailsStatus={fetchingBookingDetailsStatus}
                fetchingBookingDetailsRes={fetchingBookingDetailsRes}
                loader={loader}
                isToggleOpen={isToggleOpen}
              />
            </Suspense>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
