import React, { useEffect, useState } from 'react';

// material

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Alert,
  Button,
  FormControl,
  Grid,
  Input as InputField,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from '@components/Common/Snackbar';
import { ProfileImage } from '../ProfileImage';

import initializeAOS from '@utils/aos';

// css
import './profile.scss';

// components import
import {
  //profileUploadOnServer,
  upDateProfileDetail,
} from '@reducers/profileReducer';
import { stateData } from '@utils/jsonData';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { updatingProfile, updateProfileStatus } from '@actions/profileAction';
import { changeToLowerCase, userData, userDetails } from '@utils/common';

// component start
export const MyProfile = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    address: '',
    pincode: '',
    city: '',
    state: '',
    birthday: '',
    anniversary: '',
    gender: '',
    maritalStatus: '',
  });
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);
  const [profileImage, setProfileImage] = useState('');
  const [isChangeProfile, setIsChangeProfile] = useState(true);
  const { gettingProfileDetails, updatingProfileStatus, isProfileUpdated } =
    useSelector((state) => state.profile);

  useEffect(() => {
    initializeAOS();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'pincode' && value.length > 6) {
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setIsChangeProfile(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const data = userData(formData, profileImage);
      dispatch(upDateProfileDetail(data));
    }
  };

  useEffect(() => {
    if (gettingProfileDetails) {
      const formData = userDetails(gettingProfileDetails);
      setFormData(formData);
      setImage(formData.image);
    }
  }, [gettingProfileDetails]);

  const handleValidation = () => {
    const errors = {};
    let formIsValid = true;
    const requiredFields = ['firstName', 'email'];
    const fieldLabels = {
      firstName: 'Name',
      email: 'Email',
    };

    for (const field of requiredFields) {
      const value = formData[field];
      if (!value) {
        formIsValid = false;
        errors[field] = `Please enter ${fieldLabels[field]}`;
      } else if (field === 'email') {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailPattern.test(value)) {
          formIsValid = false;
          errors[field] = 'Invalid email address';
        }
      }
    }
    setErrors(errors);
    return formIsValid;
  };

  useEffect(() => {
    if (updatingProfileStatus) {
      setIsChangeProfile(true);
      dispatch(updatingProfile(false));
    }
  }, [updatingProfileStatus]);

  // useEffect(() => {
  //   if (profileImage) {
  //     const fileType = profileImage.type || '';
  //     if (fileType) {
  //       const fileExtension = fileType.split('/').pop();
  //       let data = {
  //         namespace: 'string',
  //         extension: fileExtension,
  //       };
  //       dispatch(profileUploadOnServer(data)).then((response) => {
  //         //setUrl(response?.payload);
  //         if (response?.payload) {
  //           setProfileImage(response?.payload);
  //         }
  //         if (response?.payload?.presignedPutUrl && profileImage) {
  //           axios
  //             .put(response?.payload?.presignedPutUrl, profileImage, {
  //               headers: {
  //                 'Content-Type': profileImage.type,
  //                 'Content-Length': profileImage.size,
  //               },
  //             })
  //             .then((axiosResponse) => {
  //               console.log('axiosResponse', axiosResponse);
  //               // Handle the response
  //               console.log('Axios POST response:', axiosResponse);
  //             })
  //             .catch((error) => {
  //               // Handle the error
  //               console.error('Axios POST error:', error);
  //             });
  //         }
  //       });
  //     }
  //   }
  // }, [profileImage, dispatch]);

  useEffect(() => {
    if (isProfileUpdated) {
      setTimeout(() => {
        dispatch(updateProfileStatus(false));
      }, 3000);
    }
  }, [isProfileUpdated]);

  const stateArray = Object.entries(stateData).map(([key, value]) => ({
    key,
    value,
  }));
  return (
    <>
      {isProfileUpdated && (
        <SnackbarProvider
          status={isProfileUpdated}
          title='Profile details updated!'
          severity='success'
        />
      )}
      {isMobile && (
        <Paper
          elevation={0}
          sx={{ padding: '16px', borderRadius: '16px' }}
          className='EL-profileMenu'
        >
          <ProfileImage
            image={image}
            setImage={setImage}
            gettingProfileDetails={gettingProfileDetails}
          />
        </Paper>
      )}
      <Paper elevation={0} sx={{ padding: '8px 0', borderRadius: '16px' }}>
        {!isMobile && (
          <div className='profileHeaderTop'>
            <div>
              <Typography variant='h4' gutterBottom>
                My Profile
              </Typography>
              <Typography variant='subtitle1' gutterBottom>
                Basic information for a seamless booking experience
              </Typography>
            </div>
            <div>
              <Button
                size='large'
                variant='contained'
                disableElevation
                onClick={handleFormSubmit}
                disabled={
                  updatingProfileStatus
                    ? updatingProfileStatus
                    : isChangeProfile
                }
                color='secondary'
              >
                UPDATE PROFILE
              </Button>
            </div>
          </div>
        )}
        <div className='profileContent'>
          <Grid container spacing={0}>
            <Grid className='El-FieldBox' item xs={12}>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' htmlFor={'input-firstName'}>
                  First Name*
                </InputLabel>
                <InputField
                  name='firstName'
                  color='secondary'
                  fullWidth={true}
                  required={true}
                  id='El-firstName'
                  variant='standard'
                  startAdornment={' '}
                  value={formData.firstName} // Assign the correct value from formData
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                  error={errors?.firstName ? true : false}
                />
                <Typography
                  className='Mui-error Mui-required'
                  style={{ color: 'red' }}
                >
                  {errors.firstName}
                </Typography>
              </FormControl>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' htmlFor={'input-lastName'}>
                  Last Name
                </InputLabel>
                <InputField
                  color='secondary'
                  fullWidth={true}
                  required={true}
                  id='El-lastName'
                  variant='standard'
                  startAdornment={' '}
                  name='lastName'
                  value={formData.lastName} // Assign the correct value from formData
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                />
              </FormControl>
            </Grid>
            <Grid className='El-FieldBox' item xs={12}>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' htmlFor={'input-mobile'}>
                  Mobile Number
                </InputLabel>
                <InputField
                  color='secondary'
                  fullWidth={true}
                  required={true}
                  id='El-mobile'
                  variant='standard'
                  placeholder='Mobile Number'
                  readOnly={true}
                  disabled={true}
                  startAdornment={' '}
                  endAdornment={
                    <Alert
                      variant='string'
                      icon={<CheckCircleIcon color='success' />}
                      severity='success'
                    >
                      Verified
                    </Alert>
                  }
                  name='mobile'
                  value={formData.mobile || gettingProfileDetails?.User?.mobile} // Assign the correct value from formData
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' htmlFor={'input-email'}>
                  Email
                </InputLabel>
                <InputField
                  color='secondary'
                  fullWidth={true}
                  required={true}
                  id='El-email'
                  variant='standard'
                  startAdornment={' '}
                  name='email'
                  value={formData.email} // Assign the correct value from formData
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                  error={errors?.email ? true : false}
                />
                <Typography
                  className='Mui-error Mui-required'
                  style={{ color: 'red' }}
                >
                  {errors.email}
                </Typography>
              </FormControl>
            </Grid>
            <Grid className='El-FieldBox' item xs={12}>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' id='demo-simple-select-label'>
                  Gender
                </InputLabel>
                <Select
                  color='secondary'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={formData.gender}
                  label='Gender'
                  name='gender'
                  startAdornment={' '}
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                  IconComponent={KeyboardArrowDownRoundedIcon}
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                  <MenuItem value={'Others'}>Others</MenuItem>
                  <MenuItem value={'No'}>Prefer not to say </MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl className='EL-profileDate' variant={'standard'}>
                  <InputLabel color='secondary' id='demo-simple-select-label'>
                    Date of Birth
                  </InputLabel>
                  <DesktopDatePicker
                    disableFuture
                    value={dayjs(formData.birthday)}
                    onChange={(newDate) => {
                      setFormData({
                        ...formData,
                        birthday: newDate,
                      });
                      setIsChangeProfile(false);
                    }}
                    format='DD-MM-YYYY'
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        error={false}
                        color='secondary'
                        fullWidth={true}
                        required={true}
                        id='El-date-picker'
                        variant='standard'
                        placeholder='DD-MM-YYYY' // Set the desired placeholder format
                        sx={{ border: 'none' }}
                        name='birthday'
                        value={dayjs(formData.birthday)}
                        onChange={handleChange}
                        disabled={updatingProfileStatus}
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid className='El-FieldBox' item xs={12}>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' id='demo-simple-select-label'>
                  Marital Status
                </InputLabel>
                <Select
                  color='secondary'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={formData.maritalStatus}
                  label='Marital Status'
                  name='maritalStatus'
                  startAdornment={' '}
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                  IconComponent={KeyboardArrowDownRoundedIcon}
                >
                  <MenuItem value={'Single'}>Single</MenuItem>
                  <MenuItem value={'Married'}>Married</MenuItem>
                  <MenuItem value={'Widowed'}>Widowed</MenuItem>
                  <MenuItem value={'Divorced'}>Divorced </MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl className='EL-profileDate' variant={'standard'}>
                  <InputLabel color='secondary' id='demo-simple-select-label'>
                    Anniversary
                  </InputLabel>
                  <DesktopDatePicker
                    disableFuture
                    value={dayjs(formData.anniversary)}
                    onChange={(newDate) => {
                      setFormData({
                        ...formData,
                        anniversary: newDate, // Update the birthday value when the date changes
                      });
                      setIsChangeProfile(false);
                    }}
                    format='DD-MM-YYYY'
                    renderInput={() => (
                      <>
                        <InputField
                          error={false}
                          color='secondary'
                          fullWidth={true}
                          required={true}
                          id='El-date-picker'
                          variant='standard'
                          placeholder='DD-MM-YYYY' // Add the placeholder here
                          startAdornment={' '}
                          sx={{ border: 'none' }}
                          name='anniversary'
                          value={dayjs(formData.anniversary)}
                          onChange={handleChange}
                          disabled={updatingProfileStatus}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid className='El-FieldBox' item xs={12}>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' htmlFor={'input-addreee'}>
                  Address
                </InputLabel>
                <InputField
                  color='secondary'
                  fullWidth={true}
                  required={true}
                  id='El-addreee'
                  variant='standard'
                  startAdornment={' '}
                  name='address'
                  value={formData?.address} // Assign the correct value from formData
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                />
              </FormControl>
              <FormControl variant={'standard'}>
                <InputLabel
                  color='secondary'
                  //error={true}
                  htmlFor={'input-Pincode'}
                >
                  Pincode
                </InputLabel>
                <InputField
                  color='secondary'
                  fullWidth={true}
                  required={true}
                  id='El-pincode'
                  variant='standard'
                  // error={true}

                  type='number'
                  startAdornment={' '}
                  name='pincode'
                  value={formData.pincode} // Assign the correct value from formData
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                />
              </FormControl>
            </Grid>
            <Grid className='El-FieldBox' item xs={12}>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' htmlFor={'input-City'}>
                  City
                </InputLabel>
                <InputField
                  color='secondary'
                  fullWidth={true}
                  required={true}
                  id='El-city'
                  variant='standard'
                  startAdornment={' '}
                  name='city'
                  value={formData.city} // Assign the correct value from formData
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                />
              </FormControl>
              <FormControl variant={'standard'}>
                <InputLabel color='secondary' id='demo-simple-select-label'>
                  State
                </InputLabel>
                <Select
                  color='secondary'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={formData.state}
                  label='State'
                  name='state'
                  startAdornment={' '}
                  onChange={handleChange}
                  disabled={updatingProfileStatus}
                  IconComponent={KeyboardArrowDownRoundedIcon}
                >
                  {stateArray?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>
                      {item?.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        {isMobile && (
          <Button
            fullWidth
            size='large'
            variant='contained'
            disableElevation
            onClick={handleFormSubmit}
            disabled={
              updatingProfileStatus ? updatingProfileStatus : isChangeProfile
            }
            color='secondary'
            className='El-profileUpdate'
          >
            UPDATE PROFILE
          </Button>
        )}
      </Paper>
    </>
  );
};
