import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

// material
import { useMediaQuery, useTheme } from '@mui/material';
import BookingDetailMobile from './Mobile';
import BookingDetailWeb from './Web';
import { ErrorDialog } from '@components/Common/ErrorDialog';
import { setOpen } from '@reducers/globalReducers';
import { useAccessTokens } from '@customhooks/useTokens';
import { useDispatch } from 'react-redux';
import { PaymentFieldModal } from '@components/BookInfo/PaymentFieldModal';

// component start
export const BookingDetailComponent = (props) => {
  const searchParams = new URLSearchParams(location?.search);
  const paymentStatus = searchParams.get('payment_status');
  const dispatch = useDispatch();
  const [openBookingBlong, setOpenBookingBlong] = useState(false);
  const { bookingDetailErrorStatus } = useSelector((state) => state?.book);
  const [fieldModal, setFieldModal] = useState(false);
  const { accessToken, refreshToken } = useAccessTokens();
  const {} = props;

  const {
    fetchingBookingDetails,
    gettingBookingDetails,
    fetchingBookingDetailsContentStatus,
    gettingBookingDetailsContentData,
  } = useSelector((state) => state.book);

  const bookingStatus = (() => {
    const stageId = gettingBookingDetails?.LifeCycleStage?.id;
    if (stageId === 1) {
      return 'error';
    } else if (stageId === 2) {
      return 'success';
    } else if (stageId === 3) {
      return 'warning';
    } else {
      return 'unknown'; // You may want to handle other cases or provide a default value
    }
  })();

  useEffect(() => {
    if (paymentStatus == 'Failed') {
      setFieldModal(true);
    }
  }, [paymentStatus]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!accessToken && !refreshToken) {
      dispatch(setOpen(true));
    }
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (bookingDetailErrorStatus?.response?.status === 403) {
      setOpenBookingBlong(true);
    }
  }, [bookingDetailErrorStatus]);

  return (
    <>
      {isMobile ? (
        <BookingDetailMobile
          openBookingBlongtoUser={
            bookingDetailErrorStatus?.response?.status === 403
          }
          fetchingBookingDetails={fetchingBookingDetails}
          gettingBookingDetails={gettingBookingDetails}
          fetchingBookingDetailsContentStatus={
            fetchingBookingDetailsContentStatus
          }
          gettingBookingDetailsContentData={gettingBookingDetailsContentData}
          bookingStatus={bookingStatus}
        />
      ) : (
        <BookingDetailWeb
          openBookingBlongtoUser={
            bookingDetailErrorStatus?.response?.status === 403
          }
          fetchingBookingDetails={fetchingBookingDetails}
          gettingBookingDetails={gettingBookingDetails}
          fetchingBookingDetailsContentStatus={
            fetchingBookingDetailsContentStatus
          }
          gettingBookingDetailsContentData={gettingBookingDetailsContentData}
          bookingStatus={bookingStatus}
        />
      )}

      <ErrorDialog
        title='Sorry! This booking does not belong to you!'
        ctaText='Okay'
        setOpenDialog={setOpenBookingBlong}
        openDialog={openBookingBlong}
        href='/'
      />
      <PaymentFieldModal open={fieldModal} setOpen={setFieldModal} />
    </>
  );
};
