import PersonIcon from '@mui/icons-material/Person';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material
import { Avatar, Badge, Stack, Typography } from '@mui/material';

import initializeAOS from '@utils/aos';

// css
import './profileImage.scss';

// components import
import { useDispatch } from 'react-redux';
//import { profileUploadOnServer } from '@reducers/profileReducer';
import { commonImageAvatar } from '@utils/common';

// component start
export const ProfileImage = (props) => {
  const { image, setImage, gettingProfileDetails } = props;
  const location = useLocation();
  const dispatch = useDispatch();

  const [profileImage, setProfileImage] = useState('');

  useEffect(() => {
    initializeAOS();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0] || null;

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setProfileImage(file);
    } else {
      setImage(null);
    }
  };

  const user = gettingProfileDetails?.User;
  const userDetails =
    user?.first_name && user?.last_name !== null
      ? `${user?.first_name} ${user?.last_name}`
      : user?.first_name && user?.last_name === null
      ? user?.first_name
      : user?.mobile;

  // useEffect(() => {
  //   if (profileImage) {
  //     const fileType = profileImage.type || '';
  //     if (fileType) {
  //       const fileExtension = fileType.split('/').pop();
  //       let data = {
  //         namespace: 'string',
  //         extension: fileExtension,
  //       };
  //       dispatch(profileUploadOnServer(data)).then((response) => {
  //         //setUrl(response?.payload);
  //         if (response?.payload) {
  //           setProfileImage(response?.payload);
  //         }
  //         if (response?.payload?.presignedPutUrl && profileImage) {
  //           axios
  //             .put(response?.payload?.presignedPutUrl, profileImage, {
  //               headers: {
  //                 'Content-Type': profileImage.type,
  //                 'Content-Length': profileImage.size,
  //               },
  //             })
  //             .then((axiosResponse) => {
  //               console.log('axiosResponse', axiosResponse);
  //               // Handle the response
  //               console.log('Axios POST response:', axiosResponse);
  //             })
  //             .catch((error) => {
  //               // Handle the error
  //               console.error('Axios POST error:', error);
  //             });
  //         }
  //       });
  //     }
  //   }
  // }, [profileImage, dispatch]);

  return (
    <>
      <div className='EL-profileAvatarMain'>
        <label htmlFor='avatar-input'>
          <Badge
            overlap='circular'
            className='EL-profileAvatar'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            //badgeContent={<CameraAltOutlinedIcon />}
          >
            {/* <Avatar alt='Travis Howard' src={image} /> */}
            <Stack className='EL-AvtarProfile' direction='row' spacing={2}>
              {gettingProfileDetails?.User?.first_name ? (
                <Avatar
                  src={PersonIcon}
                  {...commonImageAvatar(`${userDetails}`)}
                />
              ) : (
                <Avatar
                  //alt={`${PersonIcon} `}
                  sm={{ width: 50, height: 50 }}
                  xs={{ width: 20, height: 20 }}
                >
                  <PersonIcon />
                </Avatar>
              )}
            </Stack>
            {/* <Avatar
              alt='Travis Howard'
              //src={image}
            >
              {`${gettingProfileDetails?.User?.first_name?.charAt(
                0
              )} ${gettingProfileDetails?.User?.last_name?.charAt(0)}`}
            </Avatar> */}
          </Badge>
        </label>
        {/* Input for file upload */}
        {/* <input
          type='file'
          id='avatar-input'
          accept='image/*'
          style={{ display: 'none' }}
          onChange={handleFileChange}
        /> */}

        <Typography variant='h6' gutterBottom>
          {userDetails}
        </Typography>
        <Typography variant='body1' gutterBottom>
          Personal Profile
        </Typography>
      </div>
    </>
  );
};
