import { submitHubspotForm } from '@common/HubSpot';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  InputLabel,
  Paper,
  Slide,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import PropertyFilterContent from '@components/PropertyFilterBar/PropertyFilterContent';
import useListing from '@customhooks/useListing';
import {
  getCityNameFromPath,
  getValidCityName,
  isCityNameValid,
  isValidEmail,
  isValidMobileNumber,
} from '@utils/common';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CallbackSuccess from './CallbackSuccess/CallbackSuccess';
import './callBack.scss';

const CallBackRequest = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { formData, setFormData } = useListing();
  const {
    className,
    isDrawerOpen,
    setIsDrawerOpen,
    isOpenDialog,
    setIsOpenDialog,
  } = props;

  const [open, setOpen] = useState(isOpenDialog);
  const [errors, setErrors] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [storedSearchValues, setStoredSearchValues] = useState(null);
  const location = useLocation();
  const urlPathname = location.pathname;

  const { cityList } = useSelector((state) => state.property);
  const cityListData = cityList?.data;

  const { pathname } = useLocation();
  const validateCity = (city) => {
    return !city && 'City is required';
  };

  const validateCheckInDate = (checkInDate) => {
    return !checkInDate && 'Check-in Date is required';
  };

  const validateCheckOutDate = (checkOutDate) => {
    return !checkOutDate && 'Check-out Date is required';
  };

  const validateFirstName = (firstName) => {
    return !firstName && 'First name is required';
  };

  const validateLastName = (lastName) => {
    return !lastName && 'Last name is required';
  };

  const validateEmail = (email) => {
    return email
      ? isValidEmail(email)
        ? ''
        : 'Invalid email'
      : 'Email is required';
  };

  const validatePhoneNumber = (phoneNumber) => {
    return phoneNumber
      ? isValidMobileNumber(phoneNumber)
        ? ''
        : 'Invalid mobile number'
      : 'Mobile number is required';
  };

  const validateForm = (data) => {
    let error = {
      city: validateCity(data.city),
      checkInDate: validateCheckInDate(data.checkInDate),
      checkOutDate: validateCheckOutDate(data.checkOutDate),
      firstName: validateFirstName(data.firstName),
      lastName: validateLastName(data.lastName),
      email: validateEmail(data.email),
      phoneNumber: validatePhoneNumber(data.phoneNumber),
    };
    setErrors(error);
    return Object.values(error).every((error) => !error);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      const portalId = '44221819';
      const formGuid = 'e24ac1fb-c473-4ebe-9b88-43d5c465749a';
      const fields = [
        ...Object.entries(formData).map(([name, value]) => ({
          name: mapFieldName(name),
          value,
        })),
      ];
      setOpen(false);
      setSuccessModalOpen(true);
      const response = await submitHubspotForm(portalId, formGuid, fields);
      console.log('HubSpot form submission response:', response);
    } catch (error) {
      console.error('Error submitting HubSpot form:', error.message);
    }
  };

  // Function to map field names
  const mapFieldName = (name) => {
    switch (name) {
      case 'checkInDate':
        return 'check_in_date';
      case 'checkOutDate':
        return 'check_out_date';
      case 'firstName':
        return 'firstname';
      case 'lastName':
        return 'lastname';
      case 'phoneNumber':
        return 'phone';
      default:
        return name;
    }
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={isMobile ? 'up' : 'up'} ref={ref} {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (isOpenDialog) {
      setIsOpenDialog(false);
    }
  };
  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    setLoginDisabled(!validateForm(formData));
  }, [formData]);

  useEffect(() => {
    if (pathname === '/') {
      localStorage.removeItem('filterValues');
      return;
    }
    const values = localStorage.getItem('filterValues');
    if (values && cityListData) {
      const isCityValid = isCityNameValid(
        cityListData,
        getCityNameFromPath(urlPathname)
      );
      const cityName = getValidCityName(
        getCityNameFromPath(urlPathname),
        isCityValid
      );
      const parsedFilterValues = JSON.parse(values);
      const valuesObj = {
        city: cityName,
        checkInDate: parsedFilterValues?.checkInDate,
        checkOutDate: parsedFilterValues?.checkOutDate,
        numberOfGuests: parsedFilterValues?.numberOfGuests,
        numberOfChildren: parsedFilterValues?.numberOfChildren,
      };
      setStoredSearchValues(valuesObj);
    }
  }, [cityListData]);

  return (
    <>
      {!isOpenDialog && (
        <Paper className={`E-CallBackpaper ${className}`} elevation={0}>
          <Typography variant='subtitle1' gutterBottom>
            Finding your ideal vacation spot should be easy! We’re here to help.
          </Typography>
          <Button
            onClick={handleClickOpen}
            color='secondary'
            size='large'
            variant='text'
            className='EL-backHeader'
          >
            Request Callback
          </Button>
        </Paper>
      )}

      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}
        className='EL-callBackModal'
        // TransitionComponent={Transition}
      >
        {isMobile ? (
          <AppBar color='inherit' sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleClose}
                aria-label='close'
              >
                <ArrowBackRoundedIcon />
              </IconButton>
              <Typography
                onClick={handleSubmit}
                sx={{ ml: 2, flex: 1 }}
                variant='h6'
                component='div'
              >
                Request Callback
              </Typography>
            </Toolbar>
          </AppBar>
        ) : (
          <DialogTitle>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h5' gutterBottom>
              Get Assistance for Villa search
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
              Please enter your details to proceed
            </Typography>
          </DialogTitle>
        )}
        <DialogContent className='EL-CallBackModalContent'>
          <div className='El-Property-filter-content'>
            {!isMobile && (
              <Typography variant='subtitle1' gutterBottom>
                You need villa for
              </Typography>
            )}

            <PropertyFilterContent
              pageType='callback'
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              title=''
              formData={formData}
              setFormData={setFormData}
              storedSearchValues={storedSearchValues}
            />
          </div>
          <Divider textAlign='center'>Your details</Divider>
          <div className='EL-CallBackDetailField'>
            <div className='flex ai-center sp-bw'>
              <div className='el-loginField mr-16'>
                <InputLabel>First Name</InputLabel>
                <TextField
                  margin='dense'
                  placeholder={isMobile ? '' : 'Your Name'}
                  type='text'
                  name='firstName'
                  size='small'
                  fullWidth
                  value={formData.firstName}
                  onChange={handleChange}
                  focused={false}
                />
              </div>
              <div className='el-loginField'>
                <InputLabel>Last Name</InputLabel>
                <TextField
                  margin='dense'
                  placeholder={isMobile ? '' : 'Last Name'}
                  type='text'
                  name='lastName'
                  size='small'
                  fullWidth
                  value={formData.lastName}
                  onChange={handleChange}
                  focused={false}
                />
              </div>
            </div>
            <div className='flex ai-center sp-bw'>
              <div className='el-loginField'>
                <InputLabel>Email</InputLabel>
                <TextField
                  margin='dense'
                  placeholder={isMobile ? '' : 'Email'}
                  type='text'
                  name='email'
                  size='small'
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                  focused={false}
                />
              </div>
              <div className='el-loginField'>
                <InputLabel>Mobile Number</InputLabel>
                <TextField
                  margin='dense'
                  placeholder={isMobile ? '' : 'Your Phone number'}
                  type='number'
                  name='phoneNumber'
                  size='small'
                  fullWidth
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  focused={false}
                />
              </div>
            </div>
          </div>
          <Button
            disableRipple
            disabled={loginDisabled}
            fullWidth
            color='secondary'
            variant='contained'
            onClick={handleSubmit}
            className='requestCallback-btn'
          >
            Request Callback
          </Button>
        </DialogContent>
      </Dialog>
      {!isMobile ? (
        <Dialog open={successModalOpen} onClose={handleCloseSuccessModal}>
          <CallbackSuccess handleClick={handleCloseSuccessModal} />
        </Dialog>
      ) : (
        <Drawer
          anchor={'bottom'}
          open={successModalOpen}
          onClose={handleCloseSuccessModal}
          className='callback-success-drawer'
        >
          <CallbackSuccess handleClick={handleCloseSuccessModal} />
        </Drawer>
      )}
    </>
  );
};

export default CallBackRequest;
