import { getConfirmationDetails } from '@reducers/bookingReducer';
import React, { useEffect, useState } from 'react';
import useAnalytics from '@components/Common/Analytics';
// material
import { useMediaQuery, useTheme } from '@mui/material';
import initializeAOS from '@utils/aos';

// components
import { BookingInfoMobile } from './Mobile';
import { BookingInfoWeb } from './Web';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { PaymentFieldModal } from './PaymentFieldModal';

// component start
export const BookingInfo = (props) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    setInputFields,
    inputFields,
    handleSubmit,
    setTermsChecked,
    termsChecked,
    handleInputChange,
    errors,
    touchedFields,
    setBasicDetails,
    basicDetails,
    fetchingUpdatingPricing,
    gettingUpdatedPricing,
    loader,
    paymentStatus,
    bookingId,
    propertyId,
    paymentId,
    paymentRequestId,
    setIsToggleOpen,
    isToggleOpen,
  } = props;
  const [fieldModal, setFieldModal] = useState(false);
  //const [isToggleOpen, setIsToggleOpen] = useState(true);
  const [applyButtonClicked, setApplyButtonClicked] = useState(false); // Add state for Apply button click
  const { utmCampaign, utmSource } = useSelector((state) => state?.glob);
  const { confirmationDetails } = useSelector((state) => state.book);
  const searchParams = new URLSearchParams(location?.search);

  useEffect(() => {
    if (paymentRequestId) {
      dispatch(getConfirmationDetails(paymentRequestId));
    }
  }, [paymentRequestId]);

  useEffect(() => {
    initializeAOS();
  }, []);

  useEffect(() => {
    if (paymentStatus == 'Failed') {
      setFieldModal(true);
    } else if (
      paymentStatus == 'SUCCESS' &&
      (confirmationDetails?.lifecycle_stage_id == 4 ||
        confirmationDetails.lifecycle_stage_id == null)
    ) {
      // Inventory Failure
      if (confirmationDetails?.Order?.lifecycle_stage_id == 4) {
        navigate(
          `/booking/cancelled?id=${bookingId}&propertyId=${propertyId}&payment_id=${paymentId}&payment_status=${paymentStatus}&payment_gateway_code=${paymentRequestId}`
        );
        // Inventory Success
      } else if (
        confirmationDetails?.Order?.lifecycle_stage_id == 3 ||
        confirmationDetails?.Order?.lifecycle_stage_id == 5
      ) {
        navigate(
          `/booking/confirmed?id=${bookingId}&propertyId=${propertyId}&payment_status=${paymentStatus}&payment_gateway_code=${paymentRequestId}`
        );
      }
    }
  }, [
    paymentStatus,
    bookingId,
    propertyId,
    paymentId,
    paymentStatus,
    paymentRequestId,
    confirmationDetails?.Order?.lifecycle_stage_id,
  ]);

  // Property Details Page Viewed event trigger
  useEffect(() => {
    let timeoutId;
    if (bookingId) {
      timeoutId = setTimeout(() => {
        trackEvent('Review Booking Page viewed', {
          paymentStatus: paymentStatus,
          bookingId: bookingId,
          propertyId: propertyId,
          paymentId: paymentId,
          paymentRequestId: paymentRequestId,
          salutation: inputFields?.salutation,
          first_name: inputFields?.first_name,
          last_name: inputFields?.last_name,
          email: inputFields?.email,
          mobile: inputFields?.mobile,
          utmCampaign: utmCampaign,
          utmSource: utmSource,
        });
      }, 3000);
    }

    // Clean up the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [bookingId]); // Run the effect only when the title prop changes

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile ? (
        <BookingInfoMobile
          setInputFields={setInputFields}
          inputFields={inputFields}
          handleSubmit={handleSubmit}
          setTermsChecked={setTermsChecked}
          termsChecked={termsChecked}
          errors={errors}
          handleInputChange={handleInputChange}
          setBasicDetails={setBasicDetails}
          fetchingUpdatingPricing={fetchingUpdatingPricing}
          gettingUpdatedPricing={gettingUpdatedPricing}
          loader={loader}
          setIsToggleOpen={setIsToggleOpen}
          isToggleOpen={isToggleOpen}
          applyButtonClicked={applyButtonClicked}
          setApplyButtonClicked={setApplyButtonClicked}
        />
      ) : (
        <BookingInfoWeb
          setInputFields={setInputFields}
          inputFields={inputFields}
          handleSubmit={handleSubmit}
          setTermsChecked={setTermsChecked}
          termsChecked={termsChecked}
          errors={errors}
          handleInputChange={handleInputChange}
          setBasicDetails={setBasicDetails}
          basicDetails={basicDetails}
          fetchingUpdatingPricing={fetchingUpdatingPricing}
          gettingUpdatedPricing={gettingUpdatedPricing}
          loader={loader}
          setIsToggleOpen={setIsToggleOpen}
          isToggleOpen={isToggleOpen}
          applyButtonClicked={applyButtonClicked}
          setApplyButtonClicked={setApplyButtonClicked}
        />
      )}
      <PaymentFieldModal open={fieldModal} setOpen={setFieldModal} />
    </>
  );
};
