import { Edit } from '@assets';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Skeleton from '@components/Common/Skeleton';
import { isPropertyPaymentTermId } from '@utils/common';
import { CONSTANT_PAGES_URL } from '@utils/constants';
import { bookingInfo, progress } from '@utils/customText';

// material

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {
  Alert,
  Button,
  Container,
  Grid,
  Typography,
  Card,
} from '@mui/material';
import { setOpen } from '@reducers/globalReducers';
import { useDispatch, useSelector } from 'react-redux';

// components
const CheckinCheckoutDetail = lazy(() =>
  import('@components/DetailPage/CheckinCheckoutField')
);
const GuestDetailForm = lazy(() => import('../GuestDetailForm'));
const PaymentDetails = lazy(() => import('../PaymentDetail'));
const PartialSwitch = lazy(() => import('../PartialSwitch'));
const ApplyCoupon = lazy(() => import('../CouponApply'));
const ContentDetail = lazy(() => import('@components/Common/Content'));
const PropertyInfo = lazy(() => import('../PropertyInfo'));

// hooks
import useBookingDetails from '@customhooks/useBookingDetails';
import { useAccessTokens } from '@customhooks/useTokens';

// css
import { calculateMinimumStay } from '@utils/common';
import dayjs from 'dayjs';
import './bookingInfoWeb.scss';
import RedeemeElicash from '@components/Common/wallet/RedeemeElicash';

// component start
export const BookingInfoWeb = (props) => {
  const {
    setInputFields,
    inputFields,
    handleSubmit,
    setTermsChecked,
    termsChecked,
    errors,
    handleInputChange,
    touchedFields,
    setBasicDetails,
    basicDetails,
    fetchingUpdatingPricing,
    gettingUpdatedPricing,
    loader,
    setIsToggleOpen,
    isToggleOpen,
    applyButtonClicked,
    setApplyButtonClicked,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();

  const { pagesDetailsSectionsData } = useSelector((state) => state.property);
  const isPolicyContent = pagesDetailsSectionsData;
  const {
    totalNumberOfNights,
    fetchingBookingDetailsStatus,
    fetchingBookingDetailsRes,
    isModifyClicked,
    setIsModifyClicked,
  } = useBookingDetails();

  const { open } = useSelector((state) => state.glob);
  const { minimumStay, propertyDetail } = useSelector(
    (state) => state.property
  );
  const [minimumDays, setMinimumDays] = useState(0);

  const max_occupancy = localStorage.getItem('max_occupancy');
  const { accessToken, refreshToken } = useAccessTokens();
  const searchParams = new URLSearchParams(location?.search);
  const propertyId = searchParams.get('propertyId');
  const bookingId = searchParams.get('id');
  const appliedCouponCode = {
    customCoupon: null,
    sitewideCoupon: null,
    doubleDipCoupon: null,
    hollowCoupon: null,
  };

  if (
    fetchingBookingDetailsRes?.attached_coupons?.length > 0 ||
    fetchingBookingDetailsRes?.Order?.Coupons.length > 0
  ) {
    if (fetchingBookingDetailsRes?.attached_coupons?.length > 0) {
      fetchingBookingDetailsRes?.attached_coupons?.forEach((coupon) => {
        let couponObj = {
          name: coupon.Coupon.code,
          couponId: coupon.coupon_id,
          orderID: coupon.order_id,
          discountAmount: coupon.discount_amount,
        };
        if (coupon.Offer.is_custom && !coupon.Coupon.is_double_dip) {
          appliedCouponCode.customCoupon = couponObj;
        } else if (!coupon.Offer.is_custom) {
          appliedCouponCode.sitewideCoupon = couponObj;
        } else if (coupon.Coupon.is_double_dip) {
          appliedCouponCode.doubleDipCoupon = couponObj;
        }
      });
    }
    if (fetchingBookingDetailsRes?.Order?.Coupons.length > 0) {
      fetchingBookingDetailsRes?.Order?.Coupons?.forEach((coupon) => {
        let couponObj = {
          name: coupon.OrderCoupon.Coupon.code,
          couponId: coupon.OrderCoupon.Coupon.id,
          orderID: fetchingBookingDetailsRes?.Order?.id,
          discountAmount: coupon.OrderCoupon.discount_amount,
        };

        if (coupon.Offer.hollow_offer) {
          appliedCouponCode.hollowCoupon = couponObj;
        }
      });
    }
  }
  const handleNavigateToBackPage = () => {
    const updatedName = fetchingBookingDetailsRes?.Property?.name.replace(
      /[\s-]+/g,
      '-'
    );
    const formattedCityName = fetchingBookingDetailsRes?.Property?.Address?.city
      .replace(/ /g, '-')
      .toLowerCase();
    const { slug } = propertyDetail;
    const reviewBookingURL = slug
      ? `/villas-in-${formattedCityName}/${slug}?propertyId=${propertyId}&bookingId=${bookingId}`
      : `/villas-in-${formattedCityName}?propertyId=${propertyId}&bookingId=${bookingId}`;
    navigate(reviewBookingURL);
  };

  const handleLoginModalOpen = () => {
    dispatch(setOpen(true));
  };

  const pathId = fetchingBookingDetailsRes?.Order?.id;

  useEffect(() => {
    setIsToggleOpen(isPropertyPaymentTermId(gettingUpdatedPricing));
  }, [gettingUpdatedPricing]);

  const checkInDate = dayjs(fetchingBookingDetailsRes?.checkin_on).format(
    'DD MMM, YYYY'
  );
  const checkOutDate = dayjs(fetchingBookingDetailsRes?.checkout_on).format(
    'DD MMM, YYYY'
  );

  const millisecondsDiff = dayjs(checkOutDate).diff(dayjs(checkInDate), 'day');
  const numberOfNights = Math.ceil(millisecondsDiff);
  useEffect(() => {
    const minStays = calculateMinimumStay(
      checkInDate,
      checkOutDate,
      minimumStay
    );
    setMinimumDays(minStays);
  }, [checkInDate, checkOutDate]);

  const checkboxEnable = true;
  const checkboxDisable = false;

  return (
    <>
      <Container className='aboutContainer' disableGutters maxWidth='xl'>
        <Container className='bookingContainer' maxWidth='lg'>
          <Grid container spacing={10}>
            <Grid item xs={8}>
              <Grid container spacing={5}>
                {/* back button  */}
                <Grid className='EL-PDBack' item xs={12}>
                  <Button
                    className='EL-backNave'
                    onClick={handleNavigateToBackPage}
                    // startIcon={<ArrowBackIosIcon fontSize='inherit' />}
                    color='secondary'
                    size='large'
                    variant='text'
                  >
                    <ArrowBackIosIcon fontSize='inherit' />{' '}
                    {bookingInfo.reviewBooking}
                  </Button>
                </Grid>

                {/* selected property detail   */}
                <Grid className='EL-PDInfo' item xs={12}>
                  <Suspense
                    fallback={
                      <div className='flex flex-a-fs full-width'>
                        <Skeleton
                          variant='rectangular'
                          height={160}
                          width={200}
                        />
                        <div className='full-width'>
                          <Skeleton variant='text' height={60} />
                          <Skeleton variant='text' height={20} />
                          <Skeleton variant='text' height={20} />
                          <Skeleton variant='text' height={20} />
                        </div>
                      </div>
                    }
                  >
                    <PropertyInfo
                      fetchingBookingDetailsStatus={
                        fetchingBookingDetailsStatus
                      }
                      fetchingBookingDetailsRes={fetchingBookingDetailsRes}
                    />
                  </Suspense>
                </Grid>

                {/* checkin_on - checkout_on detail   */}
                <Grid className='EL-PDcheckinCheckout' item xs={12}>
                  <Typography variant='h6' gutterBottom>
                    <span>
                      {' '}
                      {bookingInfo.bookingFor.booking} {totalNumberOfNights}{' '}
                      {bookingInfo.bookingFor.nights}
                    </span>
                    <Button
                      onClick={() => setIsModifyClicked(true)}
                      startIcon={<img src={Edit} alt='icon' />}
                      color='secondary'
                      size='small'
                      variant='text'
                    >
                      {bookingInfo.modify}
                    </Button>
                  </Typography>
                  <div
                    className='El-checkinCheckoutBooking'
                    // className={`${
                    //   isModifyClicked && 'El-checkinCheckoutDisable'
                    // } El-checkinCheckoutBooking`}
                  >
                    <Suspense
                      fallback={<Skeleton variant='rectangular' height={70} />}
                    >
                      <CheckinCheckoutDetail
                        id={fetchingBookingDetailsRes?.property_id}
                        setBasicDetails={setBasicDetails}
                        basicDetails={basicDetails}
                        isbookNow={false}
                        max_occupancy={max_occupancy}
                        isModifyClicked={isModifyClicked}
                        setIsModifyClicked={setIsModifyClicked}
                        standard_guests={
                          fetchingBookingDetailsRes?.standard_occupancy
                        }
                        max_children={fetchingBookingDetailsRes?.max_children}
                        max_adults={fetchingBookingDetailsRes?.max_adults}
                        gettingUpdatedPricing={gettingUpdatedPricing}
                        applyButtonClicked={applyButtonClicked}
                        staahMapping={fetchingBookingDetailsRes?.staahMapping}
                      />
                      {fetchingBookingDetailsStatus &&
                        numberOfNights < minimumDays && (
                          <Alert
                            severity='error'
                            variant='standard'
                            className='EL-minStay-alert'
                          >
                            Please select at least {minimumDays} nights
                          </Alert>
                        )}
                    </Suspense>
                  </div>
                </Grid>
                {/* Guest detail form   */}
                <Grid className='EL-PDGuestForm' item xs={12}>
                  <div className='EL-PDGuestFormInner'>
                    <Typography variant='h6' gutterBottom>
                      {bookingInfo.guestDetails}
                    </Typography>
                    <Typography variant='caption' display='block' gutterBottom>
                      <b>{bookingInfo.note.note}</b> {bookingInfo.note.noteText}
                    </Typography>
                    {!accessToken && !refreshToken ? (
                      <Button
                        disableElevation
                        variant='outlined'
                        size='large'
                        color='secondary'
                        onClick={handleLoginModalOpen}
                        handleLoginModalOpen
                        fullWidth
                        endIcon={<ChevronRightRoundedIcon />}
                        className='El-reviewLoginCta'
                      >
                        <span>Login now to checkout</span>
                      </Button>
                    ) : (
                      ''
                    )}

                    <Suspense fallback={<div>{progress.loading}</div>}>
                      <GuestDetailForm
                        setInputFields={setInputFields}
                        inputFields={inputFields}
                        errors={errors}
                        handleInputChange={handleInputChange}
                      />
                    </Suspense>
                  </div>
                </Grid>

                {/* Policy and Rules */}
                <Suspense fallback={<div>{progress.loading}</div>}>
                  <ContentDetail
                    url={CONSTANT_PAGES_URL.bookingReview}
                    isExpand={false}
                  />
                </Suspense>
              </Grid>
            </Grid>

            {/* Price Detail */}
            <Grid className='EL-PDPaymentDetail' item xs={4}>
              <Suspense fallback={<div>{progress.loading}</div>}>
                <ApplyCoupon
                  id={pathId}
                  appliedCouponCode={appliedCouponCode}
                  fetchingBookingDetailsRes={fetchingBookingDetailsRes}
                  propertyId={propertyId}
                  applyButtonClicked={applyButtonClicked}
                  setApplyButtonClicked={setApplyButtonClicked}
                />
              </Suspense>
              {accessToken && refreshToken && (
                <Card
                  variant='outlined'
                  className='EL-RedeemClash El-paymentDetail El-PartialSwitch'
                >
                  <RedeemeElicash
                    checkboxEnable={checkboxEnable}
                    checkboxDisable={checkboxDisable}
                    fetchingBookingDetailsRes={fetchingBookingDetailsRes}
                  />
                </Card>
              )}
              {/* Partial Switch */}
              <Suspense fallback={<div>{progress.loading}</div>}>
                {/* PARTIAL PAYMENT SWITCH */}
                <PartialSwitch
                  setIsToggleOpen={setIsToggleOpen}
                  isToggleOpen={isToggleOpen}
                  outstandingPayment={
                    fetchingBookingDetailsRes?.Property?.PaymentTerms &&
                    fetchingBookingDetailsRes?.Property?.PaymentTerms[0]?.name
                  }
                />
              </Suspense>
              <Suspense
                fallback={
                  <Skeleton variant='rectangular' height={500} width={362} />
                }
              >
                <PaymentDetails
                  handleSubmit={handleSubmit}
                  setTermsChecked={setTermsChecked}
                  termsChecked={termsChecked}
                  fetchingUpdatingPricing={fetchingUpdatingPricing}
                  gettingUpdatedPricing={gettingUpdatedPricing}
                  fetchingBookingDetailsStatus={fetchingBookingDetailsStatus}
                  fetchingBookingDetailsRes={fetchingBookingDetailsRes}
                  loader={loader}
                  isToggleOpen={isToggleOpen}
                />
              </Suspense>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};
