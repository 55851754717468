import React from 'react';

// material
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import { paymentField } from '@utils/customText';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

// component startPropertyInfo
export const PaymentFieldModal = (props) => {
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='EL-FieldModal'
    >
      <DialogContent className='EL-FieldModalContent'>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          aria-label='delete'
          color='secondary'
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText
          className='EL-FieldModalText'
          id='alert-dialog-description'
        >
          <div>
            <CancelRoundedIcon />
          </div>
          <Typography variant='h4' gutterBottom>
            {paymentField.paymentFailed}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {paymentField.differentpayment}
          </Typography>
          <Button
            disableElevation
            variant='contained'
            fullWidth
            onClick={handleClose}
            size='large'
            color='secondary'
          >
            {paymentField.tryAgain}
          </Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
