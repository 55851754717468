import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material

import { Container, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';

import initializeAOS from '@utils/aos';

// css
import './myAccount.scss';

// components import
import BookingList from '@components/Account/Bookings/MyBookings';
import MyWallet from '@components/Wallet/MyWallet';
import MyProfile from '@components/Account/Profile';
import Breadcrumb from '@components/Common/Breadcrumbs';
import Skeleton from '@components/Common/Skeleton';
import Links from '@components/Header/Menu/Links';
import { useSelector } from 'react-redux';

import { ProfileImage } from './ProfileImage';
//import { useLocation } from 'react-router-dom';

const MobileHeader = lazy(() => import('@components/Common/Mobile/Header'));

const renderComponents = [
  {
    link: '/myAccount/profile',
    component: MyProfile,
    title: 'My Profile',
    class: 'EL-profileMain',
  },
  {
    link: '/myAccount/booking',
    component: BookingList,
    title: 'Manage Bookings',
    class: 'EL-booingsMain',
  },
  {
    link: '/myAccount/my-wallet',
    component: MyWallet,
    title: 'My Wallet',
    class: 'EL-myWallet',
  },
];
// component start
export const MyAccountComponent = (props) => {
  //  const location = useLocation();

  const [image, setImage] = useState(null);

  const [headerTitle, setHeaderTitle] = useState('Typography');
  const { gettingProfileDetails } = useSelector((state) => state.profile);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    initializeAOS();
  }, []);

  const { pathname } = useLocation();

  // Moved getComponentToRender outside of the component function
  const componentData = renderComponents.find(
    (component) => component.link === pathname
  );

  useEffect(() => {
    if (componentData) {
      setHeaderTitle(componentData?.title); // Set the title
    }
  }, [componentData]);

  const ComponentToRender = () => {
    if (componentData) {
      const Component = componentData.component;
      return <Component {...props} />;
    }
    return null;
  };

  const handleNavigateToBackPage = () => {
    window?.history.back();
  };

  return (
    <>
      {isMobile ? (
        <Container
          className={`profileContainerMobile  ${componentData?.class}`}
          disableGutters
          maxWidth='xl'
        >
          <Suspense
            fallback={
              <>
                <Skeleton component='h1' variant='text' height={45} />
                <Skeleton component='h3' variant='text' height={20} />
              </>
            }
            mode='concurrent'
          >
            <MobileHeader
              title={componentData?.title || headerTitle}
              onClick={handleNavigateToBackPage}
            />
          </Suspense>
          <Container className='aboutContent' maxWidth='lg'>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {ComponentToRender()}
              </Grid>
            </Grid>
          </Container>
        </Container>
      ) : (
        <Container className='profileContainer' disableGutters maxWidth='xl'>
          <Container className='aboutContent' maxWidth='lg'>
            <Breadcrumb
              className='EL-accountBreadcrumb'
              routes={[
                { path: '/', title: 'My Account' },
                {
                  path: '/profile',
                  title: componentData?.title || headerTitle,
                },
              ]}
            />
            <Grid container spacing={8}>
              <Grid item xs={3}>
                <Paper
                  elevation={0}
                  sx={{ padding: '16px', borderRadius: '16px' }}
                  className='EL-profileMenu'
                >
                  <ProfileImage
                    image={image}
                    setImage={setImage}
                    gettingProfileDetails={gettingProfileDetails}
                  />
                  <div className='EL-profileMenuList'>
                    <Links />
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={9}>
                {ComponentToRender()}
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  );
};
