import {
  Calendar,
  Contact,
  NightMoon,
  Team,
  Tripadvisor,
  WalletIcon,
  ElicashIcon,
} from '@assets';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { contact, progress } from '@utils/customText';
import React, { Suspense, lazy } from 'react';
// material
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { Avatar, Button, Card, Chip, Paper } from '@mui/material';

// material
import { ConfirmAction } from '@components/Common/Cta/ConfirmAction';
import { format, parseISO } from 'date-fns';
import '../myBooking.scss';
import Elivashcard from '@components/Common/wallet/Elivashcard';
const PropertyInfo = lazy(() => import('@components/BookInfo/PropertyInfo'));

const dateFormating = (date, type) => {
  const dateString = date;
  const dateObject = parseISO(dateString);
  return format(dateObject, type);
}; //

export const BookingCard = (props) => {
  const { bookinginfo } = props;

  const bookingStatus = (() => {
    const stageId = bookinginfo?.LifeCycleStage?.id;
    if (stageId === 1) {
      return 'info';
    } else if (stageId === 2) {
      return 'success';
    } else if (stageId === 3) {
      return 'error';
    } else if (stageId === 4) {
      return 'primary';
    } else {
      return 'warning'; // You may want to handle other cases or provide a default value
    }
  })();
  const performaInvoice = bookinginfo?.Invoice?.ProformaInvoice?.Adjustments;
  const getPriceBySlug = (slug) => {
    const adjustment = performaInvoice?.find((adj) => adj.slug === slug);
    return adjustment ? adjustment.amount : null;
  };
  const totalPayableAmount =
    getPriceBySlug('TOTAL_PAYABLE') !== null
      ? getPriceBySlug('TOTAL_PAYABLE')
      : null;
  return (
    <>
      <Card className='EL-bookingInfoCard'>
        <Chip
          className={`El-bookingStatus El-bookingStatus${bookingStatus}`}
          label={bookinginfo?.LifeCycleStage?.name}
          color={bookingStatus}
        />
        <Suspense fallback={<div>{progress.loading}</div>}>
          <div className='EL-bookingListProperty'>
            <PropertyInfo fetchingBookingDetailsRes={bookinginfo} />
          </div>
        </Suspense>

        <Paper elevation={0} className='EL-bookingInfoPaper'>
          <Chip
            avatar={<Avatar alt='Booking ID' src={Contact} />}
            label={`Booking ID - ${bookinginfo?.ota_booking_code}`}
            variant='filled'
          />
          <Chip
            label={`₹ ${parseFloat(totalPayableAmount).toFixed(2)}`}
            variant='filled'
          />
          <Chip
            avatar={<Avatar alt='dae' src={Calendar} />}
            label={`${dateFormating(
              bookinginfo?.checkin_on,
              'd MMM'
            )} - ${dateFormating(bookinginfo?.checkout_on, 'd MMM')}`}
            variant='filled'
          />
          <Chip
            avatar={<Avatar alt='Nights' src={NightMoon} />}
            label={`${bookinginfo.room_nights_count} Nights`}
            variant='filled'
          />
          <Chip
            avatar={<Avatar alt='Guests' src={Team} />}
            label={`${bookinginfo.guests_count} Guests`}
            variant='filled'
          />
          {/* <Chip
          avatar={<Avatar alt='Refund' src={Refund} />}
          label='Refund Processed'
          variant='filled'
          color='success'
        />
        <Chip
          avatar={<Avatar alt='Refund' src={Refund} />}
          label='No Refund'
          variant='filled'
        /> */}
        </Paper>
        <Paper elevation={0} className='EL-bookingActions'>
          <div>
            {bookinginfo?.lifecycle_stage_id == 2 ||
            bookinginfo?.lifecycle_stage_id == 3 ? (
              bookinginfo?.lifecycle_stage_id == 2 && (
                <ConfirmAction
                  variant='outlined'
                  avtar={<img src={Tripadvisor} />}
                  title='Review Us'
                  color='secondary'
                  action={`https://www.tripadvisor.com/UserReviewEdit-g312680-${bookinginfo?.ota_booking_code}`}
                  actionType='link'
                  endIcon={<OpenInNewIcon />}
                />
              )
            ) : (
              <>
                <ConfirmAction
                  variant='outlined'
                  avtar={<PinDropOutlinedIcon fontSize='small' />}
                  title='Get Direction'
                  color='secondary'
                  action={
                    bookinginfo?.Property?.Address?.google_maps_business_link
                  }
                  actionType='link'
                />
                <ConfirmAction
                  variant='outlined'
                  avtar={<CallOutlinedIcon fontSize='small' />}
                  title='Call Us'
                  color='secondary'
                  action={`https://wa.me/${contact.whatsApp}?text=${contact.whatsAppText}`}
                  actionType='link'
                />
              </>
            )}
          </div>
          <div>
            <ConfirmAction
              variant='contained'
              title='View Details'
              color='secondary'
              target='_self'
              action={`/booking-detail/${bookinginfo?.id}`}
              actionType='link'
            />
          </div>
        </Paper>
        <div className='EL-MyBookingAccountPage'>
          <Elivashcard
            defaultView
            elicash={
              bookinginfo?.Invoice?.cashback_on_booking
                ? bookinginfo?.Invoice?.cashback_on_booking
                : 0
            }
          />
        </div>
        {bookinginfo?.CartBooking?.Order?.lifecycle_stage_id == 5 &&
          bookinginfo?.LifeCycleStage?.id !== 3 && (
            <Button
              href={`/booking-detail/${
                bookinginfo?.id || bookinginfo?.CartBooking?.booking_id
              }`}
              color='warning'
              fullWidth
              endIcon={<ChevronRightRoundedIcon />}
              variant='contained'
              disableElevation
              className='EL-bookingsRemaining'
            >
              <span> Pay remaining amount here</span>
            </Button>
          )}
      </Card>
    </>
  );
};
