import { NoBookings } from '@assets';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import React from 'react';
// material
export const NoBookingList = (props) => {
  return (
    <div className='EL-NoBookingListMain'>
      <Card className='EL-NoBookingList' sx={{ maxWidth: 450 }}>
        <CardMedia
          loading='lazy'
          sx={{ height: 284 }}
          image={NoBookings}
          title='green iguana'
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            No bookings to show!
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            You haven’t made a booking yet. You can track your bookings from
            here!
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant='contained'
            disableElevation
            size='large'
            color='secondary'
          >
            Explore Villas
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
