import { Container, Divider, Grid } from '@mui/material';
import { progress } from '@utils/customText';

import React, { Suspense, lazy } from 'react';

import Skeleton from '@components/Common/Skeleton';
import { WalletIcon, ElicashIcon } from '@assets';
// hooks

// material
import './bookingDetails.scss';
import { CONSTANT_PAGES_URL } from '@utils/constants';

const PriceInfo = lazy(() => import('@components/Common/PriceInfo'));
const GuestInfo = lazy(() => import('@components/Common/GuestInfo'));
const ContactInfo = lazy(() => import('@components/Common/ContactInfo'));
const ContentDetail = lazy(() => import('@components/Common/Content'));
const PropertyInfo = lazy(() => import('@components/BookInfo/PropertyInfo'));
const InfoField = lazy(() => import('@components/Common/InfoField'));
const BookingHeaderTop = lazy(() => import('../HeaderTop'));
const BookingInfo = lazy(() => import('../BookingInfo'));

// hooks

const BookingDetailWeb = (props) => {
  const {
    fetchingBookingDetails,
    gettingBookingDetails,
    fetchingBookingDetailsContentStatus,
    gettingBookingDetailsContentData,
    bookingStatus,
    openBookingBlongtoUser,
  } = props;

  return (
    <Container
      className={
        openBookingBlongtoUser ? 'aboutContainer blur' : 'aboutContainer'
      }
      disableGutters
      maxWidth='xl'
    >
      <Container className='bookingContainer' maxWidth='lg'>
        <Grid container spacing={10}>
          <Grid item xs={8}>
            <Grid container spacing={3}>
              {/* back button  */}
              <Suspense fallback={<div>{progress.loading}</div>}>
                <BookingHeaderTop
                  bookingDate={gettingBookingDetails?.booking_on}
                  bookingId={gettingBookingDetails?.ota_booking_code}
                />
              </Suspense>
              <Divider className='EL-bookingDevider' variant='fullWidth' />
              {/* selected property detail   */}
              <Suspense fallback={<div>{progress.loading}</div>}>
                <BookingInfo
                  fetchingBookingDetails={fetchingBookingDetails}
                  gettingBookingDetails={gettingBookingDetails}
                  bookingStatus={bookingStatus}
                />
              </Suspense>
              <Divider className='EL-bookingDevider' variant='fullWidth' />
              {/* 
              <Grid item md={12} xs={12}>
                <div className='El-bookingActionsList'>
                  <ConfirmAction
                    variant='outlined'
                    avtar={<PinDropOutlinedIcon fontSize='small' />}
                    title='Get Directions'
                    color='secondary'
                    // action={`${confirmationDetails?.Property?.Address?.google_maps_business_link}`}
                    actionType='link'
                  />

                  <ConfirmAction
                    variant='outlined'
                    avtar={<CallOutlinedIcon fontSize='small' />}
                    title='Call Us'
                    color='secondary'
                    action={`https://wa.me/${contact.whatsApp}?text=${contact.whatsAppText}`}
                  />
                  <ConfirmAction
                    variant='contained'
                    title='Download Voucher'
                    color='secondary'
                  />
                </div>
                <Divider className='EL-bookingDevider' variant='fullWidth' />
              </Grid> */}
              {/* Guest detail form   */}

              {/* Policy and Rules */}

              <Suspense fallback={<div>{progress.loading}</div>}>
                <>
                  <ContentDetail
                    url={CONSTANT_PAGES_URL.bookingDetails}
                    isExpand={true}
                  />
                  <Divider className='EL-bookingDevider' variant='fullWidth' />
                </>
              </Suspense>
            </Grid>
          </Grid>

          {/* Price Detail */}
          <Grid className='EL-PDPaymentDetail' item xs={4}>
            <div className='bookinfDetailStickyRight'>
              <Suspense
                fallback={
                  <Skeleton variant='rectangular' height={500} width={362} />
                }
              >
                <GuestInfo
                  userInfo={gettingBookingDetails?.GuestUser?.User}
                  numberOfguests={gettingBookingDetails?.guests_count}
                  className='EL-bookingDetailContactMobile'
                />
                <PriceInfo
                  // handleSubmit={handleSubmit}
                  // setTermsChecked={setTermsChecked}
                  // termsChecked={termsChecked}
                  // fetchingUpdatingPricing={fetchingUpdatingPricing}
                  // gettingUpdatedPricing={gettingUpdatedPricing}
                  // fetchingBookingDetailsStatus={fetchingBookingDetailsStatus}
                  fetchingBookingDetailsRes={gettingBookingDetails}
                  proformaInvoice={gettingBookingDetails}
                  // loader={loader}
                  className='El-booking-detail-priceInfo'
                  isRemaning={true}
                  isAction={false}
                  getInvoice={true}
                />
                <ContactInfo />
              </Suspense>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default BookingDetailWeb;
