import React, { Suspense, lazy } from 'react';
// material
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { contact } from '@utils/customText';
import '../bookingConfirm.scss';
// component start
import { ConfirmAction } from '@components/Common/Cta/ConfirmAction';
const PropertyInfo = lazy(() => import('@components/BookInfo/PropertyInfo'));
const InfoField = lazy(() => import('@components/Common/InfoField'));

// component startPropertyInfo
const BookingCancelled = (props) => {
  const { confirmationDetails } = props;

  const preventDefault = (event) => event.preventDefault();

  return (
    <>
      <Container className='aboutContainer' disableGutters maxWidth='xl'>
        <Container className='bookingConfirmContainer' maxWidth='lg'>
          <div className='EL-bookingContainerHeader'>
            <Typography variant='subtitle2' gutterBottom>
              <span className='El-error'>Booking Failed</span>
            </Typography>
            <Typography variant='h4' gutterBottom>
              Sorry, this property has been sold out for your selected dates!
            </Typography>
          </div>
          <Grid className='EL-conformContaner' container spacing={10}>
            <Grid item md={8} xs={12}>
              <Card className='EL-bookingConfirmPaper' elevation={3}>
                <CardContent>
                  <Grid container spacing={5}>
                    {/* selected property detail   */}
                    <Grid className='EL-PDInfo' item xs={12}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <PropertyInfo
                          fetchingBookingDetailsRes={confirmationDetails}
                          inventorySoldOut={true}
                        />
                      </Suspense>
                    </Grid>

                    {/* booking details */}
                    <Grid className='EL-PDInfoBottom' item xs={12}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Grid container spacing={2}>
                          <InfoField
                            xs={6}
                            label='Pay'
                            info={`₹ ${(confirmationDetails?.Inventory?.payable_amount).toFixed(
                              2
                            )}`}
                            //cardInfo='Paid via Credit Card (XXX-9837)'
                          />
                          <InfoField
                            xs={6}
                            label='Booking ID'
                            info={`${confirmationDetails?.Booking?.ota_booking_code}`}
                          />

                          <Typography
                            variant='body1'
                            className='El-error El-refundMassage'
                            gutterBottom
                          >
                            Refund amount will be credited to the same source of
                            the payment.
                          </Typography>
                        </Grid>
                      </Suspense>
                    </Grid>
                    {/* booking details end */}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* booking right  action button */}
            <Grid className='El-confirmStickyRight' item md={4} xs={12}>
              <ConfirmAction
                variant='outlined'
                avtar={<CallOutlinedIcon fontSize='small' />}
                title='Contact Us'
                color='info'
                action={`https://wa.me/${contact.whatsApp}?text=${contact.whatsAppText}`}
              />
              <ConfirmAction
                variant='outlined'
                title=' Go to Home'
                color='info'
                action={'/'}
                actionType='link'
              />
              <div className='El-confirmBanner'>
                <img
                  src='https://d2lhlmuanwy6qz.cloudfront.net/image_333_7398ddb392.jpg?w=164&h=164&fit=crop&auto=format'
                  srcSet='https://d2lhlmuanwy6qz.cloudfront.net/image_333_7398ddb392.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x`'
                  alt={''}
                  loading='lazy'
                />
              </div>
              <Box onClick={preventDefault} className='El-buttonBox'>
                <Link href='#' underline='always'>
                  <CircleIcon /> FAQs
                </Link>
                <Link href='#' underline='always'>
                  <CircleIcon /> +91-9988776655
                </Link>
                <Link href='#' underline='always'>
                  <CircleIcon /> Support
                </Link>
              </Box>
            </Grid>
            {/* booking right  action end */}
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default BookingCancelled;
