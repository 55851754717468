import { CallbackSuccessSvg } from '@assets';
import { Button, Typography } from '@mui/material';
import React from 'react';
import './callbackSuccess.scss';
import Svg from '@components/Common/Svg';

const CallbackSuccess = ({ handleClick }) => {
  return (
    <div className='el-success-container'>
      <div>
        <Svg src={CallbackSuccessSvg} className='successImage' alt='success' />
      </div>
      <Typography variant='h5' component='h5'>
        Callback Request Sent
      </Typography>
      <Typography variant='subtitle1' component='p'>
        Our team will connect you soon for your specific villa search.
      </Typography>
      <Button
        fullWidth
        size='large'
        variant='contained'
        disableElevation
        onClick={handleClick}
        color='primary'
        className='callbackSuccess-btn'
      >
        Okay
      </Button>
    </div>
  );
};

export default CallbackSuccess;
