import Skeleton from '@components/Common/Skeleton';
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
// material
import { useAccessTokens } from '@customhooks/useTokens';
import { getBookingListingDetails } from '@reducers/bookingReducer';
import { useDispatch, useSelector } from 'react-redux';
import { BookingCard } from './BookingCard';
import { NoBookingList } from './NoBookings';

export const BookingList = (props) => {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAccessTokens();

  useEffect(() => {
    dispatch(getBookingListingDetails());
  }, [accessToken, refreshToken]);

  const { fetchBookingListingStatus, getBookingListing } = useSelector(
    (state) => state.book
  );

  // Filter bookings based on their status
  const stayBookings =
    getBookingListing.find((item) => item.name === 'STAY')?.bookings || [];
  const upcomingBookings =
    getBookingListing.find((item) => item.name === 'UPCOMING')?.bookings || [];
  const pastBookings =
    getBookingListing.find((item) => item.name === 'PAST')?.bookings || [];

  return (
    <div className='El-MybookingList'>
      {!fetchBookingListingStatus ? (
        <>
          {stayBookings?.length > 0 ||
          upcomingBookings?.length > 0 ||
          pastBookings?.length > 0 ? (
            <>
              {stayBookings?.length > 0 && (
                <div className='El-MybookingListItem'>
                  <Typography variant='h6' gutterBottom>
                    You’re staying at...
                  </Typography>
                  {stayBookings.map((booking) => (
                    <BookingCard key={booking.id} bookinginfo={booking} />
                  ))}
                </div>
              )}
              {upcomingBookings?.length > 0 && (
                <div className='El-MybookingListItem'>
                  <Typography variant='h6' gutterBottom>
                    Upcoming stay...
                  </Typography>
                  {upcomingBookings.map((booking) => (
                    <BookingCard key={booking.id} bookinginfo={booking} />
                  ))}
                </div>
              )}
              {pastBookings?.length > 0 && (
                <div className='El-MybookingListItem'>
                  <Typography variant='h6' gutterBottom>
                    Past stays...
                  </Typography>
                  {pastBookings.map((booking) => (
                    <BookingCard key={booking.id} bookinginfo={booking} />
                  ))}
                </div>
              )}
            </>
          ) : (
            <NoBookingList />
          )}
        </>
      ) : (
        Array.from({ length: 2 }, (_, index) => (
          <Skeleton
            key={index}
            variant='rectangular'
            height={278}
            width={'100%'}
          />
        ))
      )}
    </div>
  );
};
