import Skeleton from '@components/Common/Skeleton';
import { Container, Divider, Grid } from '@mui/material';
import { progress } from '@utils/customText';
import React, { Suspense, lazy } from 'react';
import { useNavigate } from 'react-router';
// material

// hooks

// material
import './bookingDetailsMobile.scss';
import { CONSTANT_PAGES_URL } from '@utils/constants';

const MobileHeader = lazy(() => import('@components/Common/Mobile/Header'));
const PriceInfo = lazy(() => import('@components/Common/PriceInfo'));
const GuestInfo = lazy(() => import('@components/Common/GuestInfo'));
const ContentDetail = lazy(() => import('@components/Common/Content'));
const BookingHeaderTop = lazy(() => import('../HeaderTop'));
const BookingInfo = lazy(() => import('../BookingInfo'));

// hooks
const BookingDetailMobile = (props) => {
  const {
    fetchingBookingDetails,
    gettingBookingDetails,
    fetchingBookingDetailsContentStatus,
    gettingBookingDetailsContentData,
    bookingStatus,
    openBookingBlongtoUser,
  } = props;
  const navigate = useNavigate();

  const handleNavigateToBackPage = () => {
    window?.history.back();
  };

  return (
    <>
      <Suspense
        fallback={
          <>
            <Skeleton component='h1' variant='text' height={45} />
            <Skeleton component='h3' variant='text' height={20} />
          </>
        }
        mode='concurrent'
      >
        <MobileHeader
          title='Booking Details'
          onClick={handleNavigateToBackPage}
        />
      </Suspense>
      <Container
        className={
          openBookingBlongtoUser ? 'aboutContainer blur' : 'aboutContainer'
        }
        disableGutters
        maxWidth='xl'
      >
        <Container className='bookingContainerMobile' maxWidth='lg'>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={0}>
                {/* back button  */}
                <Suspense fallback={<div>{progress.loading}</div>}>
                  <BookingHeaderTop
                    bookingDate={gettingBookingDetails?.booking_on}
                    bookingId={gettingBookingDetails?.ota_booking_code}
                  />
                </Suspense>
                <Divider className='EL-bookingDevider' variant='fullWidth' />
                {/* selected property detail   */}
                <Suspense fallback={<div>{progress.loading}</div>}>
                  <BookingInfo
                    fetchingBookingDetails={fetchingBookingDetails}
                    gettingBookingDetails={gettingBookingDetails}
                    bookingStatus={bookingStatus}
                  />
                </Suspense>
                <Divider className='EL-bookingDevider' variant='fullWidth' />
                <Suspense
                  fallback={
                    <Skeleton variant='rectangular' height={500} width={362} />
                  }
                >
                  <GuestInfo
                    userInfo={gettingBookingDetails?.GuestUser?.User}
                    numberOfguests={gettingBookingDetails?.guests_count}
                    className='EL-bookingDetailContactMobile'
                  />
                </Suspense>
                <Divider className='EL-bookingDevider' variant='fullWidth' />
                <Suspense
                  fallback={
                    <Skeleton variant='rectangular' height={500} width={362} />
                  }
                >
                  <PriceInfo
                    // handleSubmit={handleSubmit}
                    // setTermsChecked={setTermsChecked}
                    // termsChecked={termsChecked}
                    // fetchingUpdatingPricing={fetchingUpdatingPricing}
                    // gettingUpdatedPricing={gettingUpdatedPricing}
                    // fetchingBookingDetailsStatus={fetchingBookingDetailsStatus}
                    fetchingBookingDetailsRes={gettingBookingDetails}
                    proformaInvoice={gettingBookingDetails}
                    // loader={loader}
                    className='El-booking-detail-priceInfo'
                    isRemaning={true}
                    isAction={false}
                    getInvoice={true}
                  />
                </Suspense>
                <Divider
                  className='EL-bookingDevider EL-hideSpaceHR'
                  variant='fullWidth'
                />
                {/* Guest detail form   */}

                {/* Policy and Rules */}
                <Suspense fallback={<div>{progress.loading}</div>}>
                  <>
                    <ContentDetail
                      url={CONSTANT_PAGES_URL.bookingDetails}
                      isExpand={true}
                    />
                  </>
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default BookingDetailMobile;
